import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import * as React from "react";
import { useAsync } from "react-use";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useApi } from "../../containers/ApiContainer";

type User = {
  id: string;
  name: string;
  email: string;
  fbos: { id: string; name: string }[];
  fbo_employees: { fbo_id: string; _role: string }[];
};

type Props = {};

export const SuperUserAdmin: React.FC<Props> = () => {
  const { postgrest } = useApi();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editingUser, setEditingUser] = React.useState<string | null>(null);
  const [users, setUsers] = React.useState<User[]>([]);
  const { fbos } = useActiveFBOs();
  const [actionsOnSave, setActionsOnSave] = React.useState<(() => void)[]>([]);

  const { loading: loadingUsers } = useAsync(async () => {
    const results = await postgrest.rpc("get_users_with_fbos").select("*");
    setUsers(results.body);
  }, []);

  const onSave = React.useCallback(async () => {
    for (const action of actionsOnSave) {
      await action();
    }
    setActionsOnSave([]);
  }, [actionsOnSave]);

  const onChangeRole = React.useCallback(
    async (
      user_id: string,
      user_display_name: string,
      fbo_id: string,
      _role: string
    ) => {
      if (_role === "none") {
        await postgrest
          .from("fbo_employees")
          .delete()
          .eq("user_id", user_id)
          .eq("fbo_id", fbo_id);
      } else {
        // super annoying but postgres doesn't do upserts on non-primary keys
        await postgrest.rpc("modify_role", {
          _user_id: user_id,
          _fbo_id: fbo_id,
          __role: _role,
          _user_display_name: user_display_name,
        });
      }
      const results = await postgrest.rpc("get_users_with_fbos").select("*");
      setUsers(results.body);
    },
    []
  );

  if (loadingUsers) {
    return <div>Loading...</div>;
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "User ID",
      width: 100,
      editable: false,
      valueGetter: (_, row) => row.id.slice(0, 7) || "---",
    },
    {
      field: "name",
      headerName: "Name",
      width: 175,
      editable: false,
      valueGetter: (_, row) => row.name || "---",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
      valueGetter: (_, row) => row.email || "---",
    },
    {
      field: "fbos",
      headerName: "FBO",
      width: 400,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} alignItems="center" height="100%">
          {params.row.fbos
            .sort((a, b) => a.name.localeCompare(b.name))
            .slice(0, 5)
            .map((fbo) => (
              <Chip
                key={fbo.id}
                size="small"
                variant="outlined"
                label={fbo.name}
              />
            ))}
          {params.row.fbos.length > 5 && (
            <Chip
              size="small"
              variant="outlined"
              label={`+ ${params.row.fbos.length - 5} more`}
            />
          )}
        </Stack>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} alignItems="center" height="100%">
          <Button
            variant="contained"
            color="info"
            size="small"
            onClick={() => {
              setEditingUser(params.row.id);
              setActionsOnSave([]);
            }}
          >
            Edit
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: "100%", minWidth: 700, p: 1 }}
      >
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </div>
      </Stack>
      <Dialog
        open={!!editingUser}
        onClose={() => {
          setEditingUser(null);
          setActionsOnSave([]);
        }}
      >
        <DialogTitle>
          <Typography variant="inherit">Edit User</Typography>
        </DialogTitle>
        <DialogContent>
          <Box height={500} width={500}>
            <DataGrid
              rows={fbos}
              columns={[
                {
                  field: "name",
                  headerName: "Name",
                  width: 200,
                },
                {
                  field: "permissions",
                  headerName: "Permissions",
                  width: 200,
                  renderCell: (params: GridRenderCellParams) => {
                    const user = users.find((u) => u.id === editingUser);
                    const userRole =
                      user?.fbo_employees.find(
                        (e) => e.fbo_id === params.row.id
                      )?._role || "none";
                    return (
                      <Stack
                        direction="column"
                        height="100%"
                        justifyContent="center"
                      >
                        <TextField
                          select
                          fullWidth
                          size="small"
                          defaultValue={userRole}
                          onChange={async (evt) => {
                            setActionsOnSave([
                              ...actionsOnSave,
                              async () =>
                                await onChangeRole(
                                  user.id,
                                  user.email,
                                  params.row.id,
                                  evt.target.value
                                ),
                            ]);
                          }}
                        >
                          <MenuItem value="none">none</MenuItem>
                          <MenuItem value="manager">manager</MenuItem>
                          <MenuItem value="operator">operator</MenuItem>
                        </TextField>
                      </Stack>
                    );
                  },
                },
              ]}
              pageSizeOptions={[1000]}
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditingUser(null);
              setActionsOnSave([]);
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={async () => {
              setLoading(true);
              await onSave();
              setLoading(false);
              setEditingUser(null);
            }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
