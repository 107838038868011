import { Divider, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { orderBy } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { scrollSx } from "../../utils";
import { AddFBO } from "../../widgets/AddFBO";
import { ButtonRemoveFBO } from "../Settings/UberAdmin/ButtonRemoveFBO";
import { EditFboName } from "./EditFboName";

type Props = {};

export const FBOAdmin: React.FC<Props> = () => {
  const { fbos, setFBO } = useActiveFBOs();
  const sortedFBOs = React.useMemo(() => {
    return orderBy(fbos, ["name"], ["asc"]);
  }, [fbos]);

  return (
    <Stack direction="column" spacing={4} sx={{ width: 400, p: 1 }}>
      <Stack direction="column" spacing={1}>
        <Stack direction="column">
          <Typography>Serious Business</Typography>
          <Typography variant="caption" color="textSecondary">
            For serious people who do serious business, there are these buttons.
            Use them wisely.
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Add a new FBO</Typography>
          <AddFBO />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>
            Delete <u>this</u> FBO
          </Typography>
          <ButtonRemoveFBO />
        </Stack>
      </Stack>

      <Stack direction="column" spacing={1}>
        <Stack direction="column">
          <Typography>Current FBOs</Typography>
          <Typography variant="caption" color="textSecondary">
            Modify the text input to change the FBO Name. Change their
            subscription (gated feature access) by selecting from the dropdown.
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="column"
          spacing={1}
          maxHeight={450}
          overflow="auto"
          p={1}
          sx={{ ...scrollSx }}
        >
          {sortedFBOs.map((fbo) => (
            <Stack
              key={`fbo-${fbo.id}`}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <EditFboName
                name={fbo?.name}
                onChange={(name) => {
                  setFBO({
                    ...fbo,
                    name: name,
                  });
                }}
              />
              <TextField
                size="small"
                select
                fullWidth
                label="Subscription"
                value={fbo.subscription}
                onChange={(evt) => {
                  setFBO({
                    ...fbo,
                    subscription: evt.target.value as any,
                  });
                }}
              >
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"autostacking"}>Autostacking</MenuItem>
                <MenuItem value={"autostacking_with_edit"}>
                  Autostacking with Hangar/Ramp Edit
                </MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
              </TextField>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
