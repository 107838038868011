import { MuiColorInput, MuiColorInputColors } from "mui-color-input";

import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useLocalRampState } from "../RampEditorPresenter";
import { Shape } from "./RampCanvas";
import { smoothLine } from "./math";
import { FILL_PATTERNS } from "./patterns/usePatterns";
import { updateAtId } from "./utils";

export const TAG_OPTIONS = ["hangar", "parkable", "obstruction"];

export const PREFIXED_COLORS = [
  {
    label: "Parking Area",
    stroke_style: "#3c6ce9",
    fill_style: "rgba(238, 239, 243, 0.38)",
  },
  {
    label: "Hazard Area",
    stroke_style: "#FF0000",
    fill_style: "#FF000030",
  },
  {
    label: "Taxi / Runway Area",
    stroke_style: "#eed202",
    fill_style: "#eed20230",
  },
  {
    label: "Free Space Area",
    stroke_style: "#00FF00",
    fill_style: "rgba(0, 255, 0, 0.38)",
  },
  {
    label: "Ground Vehicles Area",
    stroke_style: "#FCFAFF",
    fill_style: "#FCFAFF30",
  },
  {
    label: "Taxi Line",
    stroke_style: "#eed202",
    fill_style: "#eed20230",
  },
  {
    label: "Runway Lines",
    stroke_style: "#FFFFFF",
    fill_style: "#FFFFFF30",
  },
  {
    label: "Hangar Area",
    fill_style: "#c8cfde50",
    stroke_style: "#3e5d8c",
  },
];

export const SHAPE_COLORS = [
  "rgba(255, 0, 0, 0.5)",
  "rgba(255, 255, 0, 0.5)",
  "rgba(0, 255, 0, 0.5)",
  "rgba(0, 0, 255, 0.5)",
];

export const TEXT_COLORS = [
  "black",
  "white",
  "red",
  "green",
  "blue",
  "yellow",
  "purple",
  "orange",
  "pink",
  "brown",
];

export const LINE_STYLES = ["solid", "dashed", "dotted"];

type Props = {
  contextMenu: {
    x: number;
    y: number;
    shapeId: string;
  } | null;
  setContextMenu: (contextMenu: Props["contextMenu"]) => void;
  onClose: () => void;
  shapes: Shape[];
  setShapes: (shapes: Shape[]) => void;
  canvasHeight: number;
};

export const ShapeEditDialog: React.FC<Props> = ({
  contextMenu,
  setContextMenu,
  onClose,
  shapes,
  setShapes,
  canvasHeight,
}) => {
  const { activeShapes, setActiveShapes } = useLocalRampState();
  const shape = React.useMemo(
    () => shapes.find((s) => s.id === contextMenu?.shapeId),
    [shapes, contextMenu?.shapeId]
  );
  const setSelectedShapes = (attribute: string, value: any) => {
    const updatedShapes = [...shapes];
    updatedShapes.forEach((shape) => {
      if (
        activeShapes.includes(shape.id) ||
        shape.id === contextMenu?.shapeId
      ) {
        shape[attribute] = value;
      }
    });
    setShapes(updatedShapes);
  };

  if (!shape) {
    return null;
  }

  const prefixedValue = PREFIXED_COLORS.find(
    (option) =>
      option.stroke_style === shape.stroke_style &&
      option.fill_style === shape.fill_style
  )?.label;

  return (
    <Drawer
      open={!!contextMenu}
      onClose={onClose}
      anchor="left"
      hideBackdrop={true}
    >
      <Box p={2} sx={{ position: "relative" }}>
        <Stack direction="column" spacing={1} alignItems="flex-start">
          <Typography variant="h6">Shape Properties</Typography>
          <FormControlLabel
            control={<Switch checked={shape?.locked} />}
            onChange={(evt) => setSelectedShapes("locked", !shape?.locked)}
            label="Locked?"
          />
          <TextField
            size="small"
            fullWidth
            label="Tags"
            select
            onChange={(event) => {
              setSelectedShapes("tags", [event.target.value]);
            }}
            value={shape?.tags ?? []}
          >
            {TAG_OPTIONS.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            fullWidth
            label="Pre-fixed Colors"
            select
            onChange={(event) => {
              const option = PREFIXED_COLORS.find(
                (option) => option.label === event.target.value
              );
              setSelectedShapes("stroke_style", option?.stroke_style);
              setSelectedShapes("fill_style", option?.fill_style);
            }}
            sx={{ zIndex: 9999 }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: { zIndex: 9999 }, // Ensure Menu aligns with Drawer
                },
              },
            }}
            value={prefixedValue ?? "custom"}
          >
            {PREFIXED_COLORS.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem value="custom">Custom</MenuItem>
          </TextField>
          {/* select color */}
          <Typography sx={{ mt: 1 }}>Fill</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <MuiColorInput
              fullWidth
              size="small"
              label="Color"
              isAlphaHidden={false}
              onChange={(value: string, colors: MuiColorInputColors) => {
                setSelectedShapes("fill_style", colors.hex8);
              }}
              value={shape?.fill_style ?? ""}
            />
            <TextField
              size="small"
              fullWidth
              label="Pattern"
              select
              onChange={(event) => {
                setSelectedShapes("fill_style", null);
                setSelectedShapes("fill_pattern", event.target.value);
              }}
              value={shape?.fill_pattern ?? ""}
            >
              {FILL_PATTERNS().map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img
                      src={option.value}
                      style={{
                        width: 24,
                        height: 24,
                        border: `1px solid darkgrey`,
                      }}
                    />
                    <Typography variant="inherit">{option.label}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Typography sx={{ mt: 1 }}>Line</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <MuiColorInput
              fullWidth
              size="small"
              label="Color"
              isAlphaHidden={false}
              onChange={(value: string, colors: MuiColorInputColors) => {
                setSelectedShapes("stroke_style", colors.hex8);
              }}
              value={shape?.stroke_style ?? ""}
            />
            {/* line style */}
            <TextField
              size="small"
              fullWidth
              label="Line Style"
              select
              value={shape?.line_style}
              onChange={(event) => {
                setSelectedShapes("line_style", event.target.value);
              }}
            >
              {[...LINE_STYLES].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Line Width"
              inputProps={{ min: 1, max: 20 }}
              onChange={(event) => {
                setSelectedShapes("line_width", Number(event.target.value));
              }}
              value={shape?.line_width}
            />
          </Stack>
          <Typography sx={{ mt: 1 }}>Text</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Font Size"
              inputProps={{ min: 10, max: 64 }}
              onChange={(event) => {
                setSelectedShapes("font_size", Number(event.target.value));
                setSelectedShapes("reference_image_height", canvasHeight);
              }}
              value={shape?.font_size}
            />
            <TextField
              size="small"
              label="Text"
              fullWidth
              onChange={(event) => {
                setSelectedShapes("text", event.target.value);
              }}
              value={shape?.text}
            />
          </Stack>
          {/* <Typography sx={{ mt: 1 }}>Position</Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Rotate</Typography>
              <IconButton
                onClick={() => {
                  const centerX =
                    shape.points.reduce((sum, p) => sum + p.x, 0) /
                    shape.points.length;
                  const centerY =
                    shape.points.reduce((sum, p) => sum + p.y, 0) /
                    shape.points.length;
                  // Rotate each point in the shape by the incremental angle change
                  const rotatedPoints = shape.points.map((point) =>
                    rotatePoint(point, { x: centerX, y: centerY }, -1)
                  );
                  const updatedShapes = [...shapes];
                  updatedShapes[contextMenu.shapeIndex].points = rotatedPoints;
                  setShapes(updatedShapes);
                }}
              >
                <RotateLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  const centerX =
                    shape.points.reduce((sum, p) => sum + p.x, 0) /
                    shape.points.length;
                  const centerY =
                    shape.points.reduce((sum, p) => sum + p.y, 0) /
                    shape.points.length;
                  // Rotate each point in the shape by the incremental angle change
                  const rotatedPoints = shape.points.map((point) =>
                    rotatePoint(point, { x: centerX, y: centerY }, 1)
                  );
                  const updatedShapes = [...shapes];
                  updatedShapes[contextMenu.shapeIndex].points = rotatedPoints;
                  setShapes(updatedShapes);
                }}
              >
                <RotateRightIcon />
              </IconButton>
            </Stack>
          </Stack> */}
        </Stack>
        <Box p={2}>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Button
              color="error"
              onClick={() => {
                // delete this shape and all selected shapes
                const updatedShapes = shapes.filter(
                  (s) =>
                    !activeShapes.includes(s.id) && s.id !== contextMenu.shapeId
                );
                setActiveShapes([]);
                setShapes(updatedShapes);
                setContextMenu(null);
              }}
            >
              Delete
            </Button>
            <Stack direction="row" spacing={1}>
              {shape?.type === "Line" && (
                <Button
                  onClick={() => {
                    let updatedShapes = [...shapes];

                    updatedShapes = updateAtId(updatedShapes, {
                      ...shape,
                      points: smoothLine(shape.points),
                    });
                    setShapes(updatedShapes);
                  }}
                >
                  Smooth Line
                </Button>
              )}
              <Button
                onClick={() => {
                  const updatedShapes = [...shapes];
                  updatedShapes.push({ ...shape });
                  setShapes(updatedShapes);
                }}
              >
                Copy
              </Button>
              <Button onClick={onClose}>Cancel</Button>
              <Button variant="contained" onClick={onClose}>
                OK
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};
