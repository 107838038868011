import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import Draggable from "react-draggable";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { Hangar, Obstacle, ObstacleType } from "../../../types";
import { formatNumber } from "../../../utils";
import { feetToMeters } from "../../../utils/math";
import { getObstacleGeometry } from "./ObstacleCanvas";

type DraggableObstacleContextMenuProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onDelete: () => void;
};

export const DraggableObstacleContextMenu: React.FC<DraggableObstacleContextMenuProps> = ({
  anchorEl,
  onDelete,
  onClose,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
    >
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Menu>
  );
};

type Props = {
  hangar: Hangar;
  feetToPixels: number;
  obstacle: Obstacle;
  onDrag: (obstacle: Obstacle) => void;
  onDelete: (obstacle: Obstacle) => void;
};

export const DraggableObstacle: React.FC<Props> = ({
  hangar,
  feetToPixels,
  obstacle,
  onDrag,
  onDelete,
}) => {
  const theme = useTheme();
  const { activeFBO } = useActiveFBOs();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const x = feetToPixels * obstacle.x;
  const y = feetToPixels * obstacle.y;

  let sx = {};
  let sxHover = {};
  if (obstacle.type === ObstacleType.OBSTRUCTION) {
    sx = {
      backgroundColor: "info.main",
      outline: "none",
    };
    sxHover = {
      backgroundColor: `${theme.palette.info.dark}20`,
      outline: "none",
    };
  } else if (obstacle.type === ObstacleType.NO_TOW_AREA) {
    sx = {
      backgroundColor: "info.error",
      outline: "2px dashed red",
    };
    sxHover = {
      backgroundColor: "#EE4B2B20",
      outline: "2px dashed #EE4B2B",
    };
  } else if (obstacle.type === ObstacleType.UNOBSTRUCTIVE) {
    sx = {
      backgroundColor: "#3c6ce990",
      outline: "none",
    };
    sxHover = {
      backgroundColor: "#3c6ce920",
      outline: "none",
    };
  } else if (obstacle.type === ObstacleType.CAUTION) {
    sx = {
      backgroundColor: "#ECE81A90",
      outline: "none",
    };
    sxHover = {
      backgroundColor: "#ECE81A20",
      outline: "none",
    };
  }

  if (!feetToPixels) {
    return null;
  }

  const BORDER_BUFFER = 0;

  const dimensions = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(obstacle.width), 1)}m x ${formatNumber(
        feetToMeters(obstacle.depth),
        1
      )}m`
    : `${formatNumber(obstacle.width, 2)}ft x ${formatNumber(
        obstacle.depth,
        2
      )}ft`;

  return (
    <>
      <Draggable
        bounds="parent"
        defaultPosition={{ x, y }}
        onDrag={(evt, data) => {
          const newObstacle = {
            ...obstacle,
            x: data.x / feetToPixels,
            y: data.y / feetToPixels,
          };
          // if outside of hangar, don't fire
          if (
            newObstacle.x < -BORDER_BUFFER ||
            newObstacle.y < -BORDER_BUFFER ||
            newObstacle.x + newObstacle.width > hangar.width + BORDER_BUFFER ||
            newObstacle.y + newObstacle.depth > hangar.depth + BORDER_BUFFER
          ) {
            evt.preventDefault();
            return;
          }
          onDrag({
            ...newObstacle,
            active: true,
            geom: getObstacleGeometry(newObstacle),
          });
        }}
        onStop={(evt, data) => {
          onDrag({
            ...obstacle,
            active: false,
          });
        }}
      >
        <Box
          onContextMenu={(evt) => {
            evt.preventDefault();
            setAnchorEl(evt.currentTarget);
          }}
          sx={{
            ...sx,
            position: "absolute",
            cursor: "grab",
            width: obstacle.width * feetToPixels,
            height: obstacle.depth * feetToPixels,
            "&:hover": {
              ...sxHover,
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", width: "100%", textAlign: "center" }}
          >
            <Typography variant="caption">{dimensions}</Typography>
          </Stack>
        </Box>
      </Draggable>
      <DraggableObstacleContextMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onDelete={() => onDelete(obstacle)}
      />
    </>
  );
};
