import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import NearMeIcon from "@mui/icons-material/NearMe";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StraightenIcon from "@mui/icons-material/Straighten";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextureIcon from "@mui/icons-material/Texture";
import TitleIcon from "@mui/icons-material/Title";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useHangarsState } from "../../../../../containers/HangarsStateContainer";
import { useMeasuringToolState } from "../../../../../containers/MeasuringToolContainer";
import { uuidv4 } from "../../../../../utils";
import { Shape } from "../RampCanvas/RampCanvas";
import { useLocalRampState, useRampEditorState } from "../RampEditorPresenter";
import { ShapeElements } from "./ShapeElements";

export interface Tool {
  name: string;
  icon: JSX.Element;
  shortcut?: string;
  onClick: () => void;
}

type Props = {
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
};

export const Toolbar: React.FC<Props> = ({ selectedTool, setSelectedTool }) => {
  const {
    showReferenceImage,
    setShowReferenceImage,
    showMeasurements,
    setShowMeasurements,
  } = useRampEditorState();
  const { ramp, setRamp, setActiveShapes } = useLocalRampState();
  const { hangars } = useHangarsState();
  const measuringToolState = useMeasuringToolState();
  const [activeTab, setActiveTab] = React.useState<string>("Tools");

  const setShapes = React.useCallback(
    (shapes: Shape[]) => {
      setRamp({
        ...ramp,
        shapes,
      });
    },
    [ramp, setRamp]
  );

  const setShape = React.useCallback(
    (idx: number, shape: Shape) => {
      setShapes(
        ramp.shapes.map((s) => {
          if (s === ramp.shapes[idx]) {
            return shape;
          }
          return s;
        })
      );
    },
    [ramp.shapes, setShapes]
  );

  const startingPoint = {
    x: ramp.width / 2,
    y: ramp.depth / 2,
  };

  const tools: Tool[] = [
    {
      name: "Select",
      icon: <NearMeIcon />,
      onClick: () => {
        setActiveShapes([]);
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Area",
      icon: <TextureIcon />,
      shortcut: "A",
      onClick: () => {
        setActiveShapes([]);
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Line",
      icon: <LinearScaleIcon />,
      shortcut: "L",
      onClick: () => {
        setActiveShapes([]);
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Tie Down T",
      icon: <TitleIcon />,
      shortcut: "Tie Down T",
      onClick: () => {
        setActiveShapes([]);
        setShapes([
          {
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            type: "Tie Down T",
            locked: false,
            points: [
              { x: 0 + startingPoint.x - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 100 },
              { x: 0 + startingPoint.x - 50, y: startingPoint.y + 100 },
            ],
            stroke_style: "black",
          },
          ...ramp.shapes,
        ]);
        setSelectedTool("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Double T",
      icon: (
        <Box
          sx={{
            position: "relative",

            width: 20, // Adjust size as needed
            height: 20,
          }}
        >
          {/* Right-side-up TitleIcon */}
          <TitleIcon
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: 20,
              color: "black",
            }}
          />
          {/* Upside-down TitleIcon */}
          <TitleIcon
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: 20,
              color: "black", // Different color for distinction
              transform: "rotate(180deg)",
            }}
          />
        </Box>
      ),
      shortcut: "Double T",
      onClick: () => {
        setActiveShapes([]);
        setShapes([
          {
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            type: "Double T",
            locked: false,
            points: [
              { x: 0 + startingPoint.x - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 100 },
              { x: 0 + startingPoint.x - 50, y: startingPoint.y + 100 },
            ],
            stroke_style: "black",
          },
          ...ramp.shapes,
        ]);
        setSelectedTool("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Circle",
      icon: <RadioButtonUncheckedIcon />,
      onClick: () => {
        setActiveShapes([]);
        setShapes([
          {
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            type: "Circle",
            locked: false,
            points: [
              { x: startingPoint.x + 0 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 100 },
              { x: startingPoint.x + 0 - 50, y: startingPoint.y + 100 },
            ],
            stroke_style: "black",
            line_style: "solid",
            line_width: 3,
            fill_style: null,
            font_size: null,
            reference_image_height: null,
            text: null,
          },
          ...ramp.shapes,
        ]);
        setSelectedTool("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Helipad",
      icon: (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 24, // Adjust size as needed
            height: 24,
          }}
        >
          <RadioButtonUncheckedIcon />
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              color: "black",
              fontWeight: "bold",
            }}
          >
            H
          </Typography>
        </Box>
      ),
      onClick: () => {
        setActiveShapes([]);
        setShapes([
          {
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            type: "Helipad",
            locked: false,
            points: [
              { x: startingPoint.x + 0 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 0 },
              { x: startingPoint.x + 100 - 50, y: startingPoint.y + 100 },
              { x: startingPoint.x + 0 - 50, y: startingPoint.y + 100 },
            ],
            stroke_style: "#f9de55",
            line_style: "solid",
            line_width: 3,
            fill_style: null,
            font_size: 50,
            reference_image_height: null,
            text: "H",
          },
          ...ramp.shapes,
        ]);
        setSelectedTool("Select");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Text",
      icon: <TextFieldsIcon />,
      onClick: () => {
        setActiveShapes([]);
        console.log("Text");
        measuringToolState.setActive(false);
      },
    },
    {
      name: "Measuring",
      icon: <StraightenIcon />,
      onClick: () => {
        setActiveShapes([]);
        measuringToolState.setActive(true);
      },
    },
  ];

  return (
    <Stack direction="column" alignItems="flex-start" spacing={1}>
      <Typography variant="subtitle2">Options</Typography>
      <Stack direction="row" spacing={1}>
        <FormControlLabel
          control={
            <Switch
              size="small"
              color="primary"
              checked={showReferenceImage}
              onChange={() => {
                setShowReferenceImage(!showReferenceImage);
              }}
            />
          }
          label="Image"
          labelPlacement="start"
        />
        <IconButton
          size="small"
          onClick={() => {
            // download the image (ramp.reference_image)
            const a = document.createElement("a");
            a.href = ramp.reference_image;
            a.download = `image-${ramp.name}.png`;
            a.click();
          }}
          sx={{ p: 0 }}
        >
          <DownloadIcon />
        </IconButton>
      </Stack>
      <FormControlLabel
        control={
          <Switch
            size="small"
            color="primary"
            checked={showMeasurements}
            onChange={() => setShowMeasurements(!showMeasurements)}
          />
        }
        label="Measurements"
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <Switch
            size="small"
            color="primary"
            checked={ramp?.version === "v2"}
            onChange={() =>
              setRamp({
                ...ramp,
                version: ramp?.version === "v2" ? "v1" : "v2",
              })
            }
          />
        }
        label="Ramp v2.0"
        labelPlacement="start"
      />
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
      >
        <Tab label="Tools" value="Tools" />
        <Tab label="Shapes" value="Shapes" />
        <Tab label="Hangars" value="Hangars" />
      </Tabs>
      {activeTab === "Tools" &&
        tools.map((tool) => (
          <Button
            key={`tool-button-${tool.name}`}
            startIcon={tool.icon}
            variant={selectedTool === tool.name ? "contained" : "text"}
            onClick={() => {
              setSelectedTool(tool.name);
              tool.onClick();
            }}
          >
            {tool.name}
          </Button>
        ))}
      {activeTab === "Shapes" && (
        <ShapeElements
          shapes={ramp.shapes}
          setShapes={setShapes}
          tools={tools}
        />
      )}
      {activeTab === "Hangars" && (
        <Stack direction="column" alignItems="flex-start" width="100%">
          {hangars.length === 0 && (
            <Typography variant="subtitle2" align="center">
              No hangars available
            </Typography>
          )}
          {hangars.map((hangar) => (
            <Button
              key={`hangar-button-${hangar.id}`}
              variant="text"
              disabled={Boolean(
                ramp.shapes.find((s) => s.hangar_id === hangar.id)
              )}
              onClick={() => {
                console.log(`${hangar.width} ft x ${hangar.depth} ft`);
                setShapes([
                  {
                    id: uuidv4(),
                    fbo_id: ramp.fbo_id,
                    ramp_id: ramp.id,
                    hangar_id: hangar.id,
                    tags: ["hangar"],
                    type: "Area",
                    locked: false,
                    line_style: "solid",
                    line_width: 3,
                    fill_style: "#c8cfde50",
                    stroke_style: "#3e5d8c",
                    text: hangar.name,
                    font_size: 12,
                    reference_image_height: null,
                    points: [
                      {
                        x: startingPoint.x + 0 - hangar.width / 2,
                        y: startingPoint.y + 0,
                      },
                      {
                        x: startingPoint.x + hangar.width - hangar.width / 2,
                        y: startingPoint.y + 0,
                      },
                      {
                        x: startingPoint.x + hangar.width - hangar.width / 2,
                        y: startingPoint.y + hangar.depth,
                      },
                      {
                        x: startingPoint.x + 0 - hangar.width / 2,
                        y: startingPoint.y + hangar.depth,
                      },
                    ],
                  },
                  ...ramp.shapes,
                ]);
              }}
              startIcon={<AddIcon />}
            >
              {hangar.name}
            </Button>
          ))}
        </Stack>
      )}

      <Divider flexItem />
    </Stack>
  );
};
