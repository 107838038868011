import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import * as React from "react";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { Hangar, Location, Ramp, Stack as StackType } from "../../../types";
import { scrollSx } from "../../../utils";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { MultiHangarFavoritesPresenter } from "./MultiHangarFavoritesPresenter";
import { MultiLocationSave } from "./MultiLocationSave";
import { SingleFavoritesPresenter } from "./SingleFavoritesPresenter";
import { SingleLocationSave } from "./SingleLocationSave";

type Props = {
  disabled?: boolean;
  location: Location<Ramp | Hangar>;
  setLocation: (location: Location<Ramp | Hangar>) => void;
  stacks: StackType[];
};

export const FavoritesButton: React.FC<Props> = ({
  disabled = false,
  location,
  setLocation,
  stacks,
}) => {
  const { isMultiHangar } = useMultiHangarState();
  const [confirm, setConfirm] = React.useState<{
    open: boolean;
    text: string | React.ReactNode;
    okText: string;
    onOk: () => void;
  }>({
    open: false,
    text: "",
    okText: "",
    onOk: () => {},
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [openNameField, setOpenNameField] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");

  const favorites = React.useMemo(() => {
    return orderBy(stacks, ["name"], ["asc"]);
  }, [location, stacks, open]);

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        color="info"
        variant="contained"
        sx={{ boxShadow: "none" }}
      >
        <Button onClick={() => setOpenNameField(true)}>
          <SaveIcon />
        </Button>
        <Button onClick={() => setOpen(true)}>Favorites</Button>
      </ButtonGroup>
      <Dialog open={openNameField} onClose={() => setOpenNameField(false)}>
        <DialogTitle>Save Favorite Stack</DialogTitle>
        <DialogContent>
          <br />
          {isMultiHangar ? (
            <MultiLocationSave
              name={name}
              setName={setName}
              setOpenNameField={setOpenNameField}
              setConfirm={setConfirm}
            />
          ) : (
            <SingleLocationSave
              location={location}
              setLocation={setLocation}
              name={name}
              setName={setName}
              setOpenNameField={setOpenNameField}
              setConfirm={setConfirm}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Favorites</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            ...scrollSx,
          }}
        >
          <Box
            sx={{
              mt: 2,
              mb: 2,
              minWidth: favorites.length > 0 ? 1000 : 300,
              height: favorites.length === 0 && 150,
            }}
          >
            {isMultiHangar ? (
              <MultiHangarFavoritesPresenter
                setOpen={setOpen}
                setConfirm={setConfirm}
              />
            ) : (
              <SingleFavoritesPresenter
                favorites={favorites}
                location={location}
                setLocation={setLocation}
                setName={setName}
                setOpen={setOpen}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {confirm?.open && (
        <ConfirmationDialog
          title={"Please Confirm"}
          onCancel={() => setConfirm(null)}
          onClose={() => setConfirm(null)}
          {...confirm}
        />
      )}
    </>
  );
};
