import { formatDistance } from "date-fns";

export const lastHangaredText = (
  value: string,
  currentlyInHangar: boolean
): string => {
  if (currentlyInHangar) {
    return "Today";
  }
  if (!value) {
    return "Never";
  }
  return formatDistance(new Date(value), new Date(), { addSuffix: true });
};

export const addTenantConformationText = (locationType: string) => {
  if (window.location.hash === "#/tenants") {
    return `We’ll add this new base tenant to the aircraft list. If you need to assign a default hangar for this aircraft, you can do so by editing the specific aircraft entry.`;
  }
  // base + hangar
  if (locationType === "hangar") {
    return "We’ll assign this new Base Tenant to this hangar. If you need to change default hangar assignments, you can do that by editing this specific aircraft.";
  }
  // base + ramp
  if (locationType === "ramp") {
    return "We’ll assign this new Tie-Down Tenant to this ramp. If you need to change default ramp assignments, you can do that by editing this specific aircraft.";
  }
  // base + tenants page
  if (!locationType) {
    return `We’ll add this new base tenant to the aircraft list. If you need to assign a default hangar for this aircraft, you can do so by editing the specific aircraft entry.`;
  }
};
