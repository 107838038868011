import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import InfoIcon from "@mui/icons-material/Info";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import { difference, featureCollection, polygon, union } from "@turf/turf";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { pointInPolygon, polygonInPolygon } from "geometric";
import { round, sum } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { getStackPolygons } from "../../hooks/utils";
import { EntityPolygon, Ramp, Tenant } from "../../types";
import { formatNumber, formatPercentage } from "../../utils";
import { feetToMeters, squareFeetToSquareMeters } from "../../utils/math";
import { Shape } from "../Settings/RampsSettings/RampEditor/RampCanvas/RampCanvas";

const TOOLTIP_MESSAGES = {
  aircraftOnRamp:
    "Aircraft parked within ramp outline / All aircraft shown on screen.",
  parkableArea:
    "Outlined ramp areas, excluding those marked as not parkable (eg taxiways, obstacles).",
  utilization:
    "Aircraft parked within ramp outline / All aircraft shown on screen.  (Note aircraft counts as parked when main wheels are within the overall ramp outline)",
  grossDimensions: "Basic length and width of ramp outline",
};

export const MicroMetric: React.FC<{
  icon: React.ReactElement;
  value: string | React.ReactNode;
  tooltip?: string;
}> = ({ icon, value, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        {icon}
        <Typography variant="caption" textAlign="center" color="info.dark">
          {value}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export const calculateGeometryArea = (geom: Polygon | MultiPolygon): number => {
  if (geom.type === "Polygon") {
    const areas = geom.coordinates.map((coords) =>
      calculatePolygonArea(coords)
    );
    return sum(areas);
  }
  if (geom.type === "MultiPolygon") {
    const areas = geom.coordinates.flatMap((coordSet) =>
      coordSet.flatMap((coords) => calculatePolygonArea(coords))
    );
    return sum(areas);
  }
  return null;
};

const calculatePolygonArea = (coordinates: number[][]) => {
  let n = coordinates.length;
  let area = 0;

  for (let i = 0; i < n - 1; i++) {
    const [x1, y1] = coordinates[i];
    const [x2, y2] = coordinates[i + 1];

    // Shoelace formula summation
    area += x1 * y2 - y1 * x2;
  }

  // Finalize the calculation (absolute value)
  return Math.abs(area) / 2;
};

export const combinePolygons = (polygons: Feature<Polygon>[]) => {
  if (!polygons.length) {
    return null;
  }
  if (polygons.length === 1) {
    return polygons[0];
  }
  return union(featureCollection(polygons));
};

export const shapeToPolygon = (shape: Shape) => {
  return polygon([
    [
      ...shape.points.map((point) => [point.x, point.y]),
      [shape.points[0].x, shape.points[0].y],
    ],
  ]);
};

export const getParkableGeometry = (
  ramp: Ramp,
  ignoreObstructions: boolean = false
) => {
  const parkablePolygons = ramp.shapes
    .filter((s) => s.tags.includes("parkable") && s.type === "Area")
    // this is actually covering up for a bug in the editor. not sure what's
    // causing it
    .filter((s) => s.points.length > 2)
    .map((shape) => shapeToPolygon(shape));

  if (!parkablePolygons.length) {
    return null;
  }

  if (ignoreObstructions) {
    return combinePolygons(parkablePolygons);
  }

  const obstructionPolygons = ramp.shapes
    .filter((s) => s.tags.includes("obstruction") && s.type === "Area")
    // this is actually covering up for a bug in the editor. not sure what's
    // causing it
    .filter((s) => s.points.length > 2)
    .map(shapeToPolygon);

  if (obstructionPolygons.length === 0) {
    return combinePolygons(parkablePolygons);
  }

  const combinedGeometry = combinePolygons(parkablePolygons);
  const obstructionGeometry = combinePolygons(obstructionPolygons);

  // remove obstruction from parkable area
  const parkableGeometry = difference(
    featureCollection([combinedGeometry, obstructionGeometry])
  );
  return parkableGeometry;
};

export const calculateUsableArea = (ramp: Ramp) => {
  try {
    const parkableGeometry = getParkableGeometry(ramp);

    if (!parkableGeometry || !parkableGeometry.geometry) {
      return 0;
    }

    const parkableArea = calculateGeometryArea(parkableGeometry.geometry);
    const obstructionArea = sum(
      parkableGeometry.geometry.coordinates
        .slice(1)
        .map((c) => calculatePolygonArea(c as number[][]))
    );

    return parkableArea - obstructionArea;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const findTenantsFullyOnRamp = (
  ramp: Ramp,
  entityPolygons: EntityPolygon[],
  parkableArea: Feature<Polygon | MultiPolygon>
): Tenant[] => {
  if (!parkableArea || !parkableArea.geometry) {
    return [];
  }

  const parkableAreaPolygon = parkableArea.geometry.coordinates
    .flat()
    // inverted coordinates on the shapes
    .map((xy) => [xy[0], ramp.depth - xy[1]]);
  const tenantIdsFullyOnRamp = entityPolygons
    .filter((entityPolygon) => {
      const { polygon } = entityPolygon;
      const isCompletedlyInside = polygonInPolygon(
        polygon as geometric.Polygon,
        parkableAreaPolygon as geometric.Polygon
      );
      if (isCompletedlyInside) {
        return true;
      }

      const bounds = entityPolygon.bounds;
      // check if the center of the entity is inside the parkable area
      const center = [
        (bounds[0][0] + bounds[1][0]) / 2,
        (bounds[0][1] + bounds[1][1]) / 2,
      ];

      if (
        pointInPolygon(
          center as geometric.Point,
          parkableAreaPolygon as geometric.Polygon
        )
      ) {
        return true;
      }
    })
    .map(({ entity_id }) => entity_id);
  const tenantsOnRamp = ramp.stack.tenants.filter((tenant) => {
    return tenantIdsFullyOnRamp.indexOf(tenant.entity_id) > -1;
  });
  return tenantsOnRamp;
};

export const calculateUtilization = (
  ramp: Ramp,
  rampParkableAreaPolygon: Feature<Polygon | MultiPolygon>,
  mustBeOnRamp: boolean = true
) => {
  const area = calculateUsableArea(ramp);
  // limit to aircraft that are within the parkable area
  const polygons = getStackPolygons(ramp.stack, -1, 1);

  const tenants = mustBeOnRamp
    ? findTenantsFullyOnRamp(ramp, polygons, rampParkableAreaPolygon)
    : ramp.stack.tenants;

  const totalUtilizatedSqft = sum(
    tenants.map((t) => t.aircraft.length * t.aircraft.wingspan)
  );
  const utilization = totalUtilizatedSqft / area;
  if (area === 0) {
    return { nFullyOnRamp: tenants.length, utilizationFormatted: "0%" };
  }
  const formatted = formatPercentage(utilization, 0);
  if (formatted === "0%" && totalUtilizatedSqft > 0) {
    return { nFullyOnRamp: tenants.length, utilizationFormatted: "<1%" };
  }
  return {
    nFullyOnRamp: tenants.length,
    utilizationFormatted: formatted,
  };
};

type Props = {
  isDrawerOpen: boolean;
  ramp: Ramp;
  onClickBack?: () => void;
};

export const RampInfoCard: React.FC<Props> = ({
  isDrawerOpen,
  ramp,
  onClickBack,
}) => {
  const { activeFBO } = useActiveFBOs();
  const rampParkableAreaPolygon = useDebouncedValue(
    () => {
      if (ramp?.version === "v2") {
        return getParkableGeometry(ramp, true);
      }
      return null;
    },
    50,
    [ramp.shapes]
  );

  // this is really expensive to calculate. so do it as little as possible.
  const { nFullyOnRamp, utilizationFormatted } = useDebouncedValue(
    () => {
      if (ramp?.version === "v2") {
        return calculateUtilization(ramp, rampParkableAreaPolygon, true);
      }
      return {
        nFullyOnRamp: 0,
        utilizationFormatted: "0%",
      };
    },
    50,
    [ramp.stack.tenants, rampParkableAreaPolygon]
  );

  const {
    utilizationFormatted: totalPossibleUtilizationFormatted,
  } = useDebouncedValue(
    () => {
      if (ramp?.version === "v2") {
        return calculateUtilization(ramp, rampParkableAreaPolygon, false);
      }
      return {
        utilizationFormatted: "0%",
      };
    },
    50,
    [ramp.stack.tenants, rampParkableAreaPolygon]
  );

  const dimensions = activeFBO?.settings?.useMetricSystem
    ? `${formatNumber(feetToMeters(ramp.depth), 0)}L x ${formatNumber(
        feetToMeters(ramp.width),
        0
      )}W`
    : `${round(ramp.depth, 0)}L x ${round(ramp.width)}W`;

  const parkableAreaDisplay = activeFBO?.settings?.useMetricSystem ? (
    <>
      {`${formatNumber(
        squareFeetToSquareMeters(calculateUsableArea(ramp)),
        0
      )} m²`}
    </>
  ) : isDrawerOpen ? (
    <>
      {`${formatNumber(calculateUsableArea(ramp) / 1000, 0)}K ft²`} (
      {`${formatNumber(calculateUsableArea(ramp) / 43560, 2)} acres`})
    </>
  ) : (
    <>{`${formatNumber(calculateUsableArea(ramp) / 1000, 0)}K ft²`}</>
  );

  /**
  // TODO: this is a work in progress. we need to calculate the hangar
  React.useEffect(() => {
    const polygons = getStackPolygons(ramp.stack, -1, 1);
    for (const polygon of polygons) {
      for (const hangar of ramp.shapes.filter((s) => Boolean(s.hangar_id))) {
        if (
          polygonInPolygon(
            polygon.polygon,
            hangar.points.map((p) => [p.x, ramp.depth - p.y])
          )
        ) {
          const tenant = ramp.stack.tenants.find(
            (t) => t.entity_id === polygon.entity_id
          );
          console.log(`${tenant?.tail_number} 'is inside' ${hangar.hangar_id}`);
          // it might not be 100% true, but the hangar is rectangular in some form or fashion
          // we need to calculate the angle of the bottom-left arc of the hangar relative to
          // true up/down. with that angle, we will translate the position (x, y, angle) of the
          // aircraft so that it's relative to the hangar.
          //
          // invoke setHangars
        }
      }
    }
  }, [ramp.stack.tenants, rampParkableAreaPolygon]);
   */

  if (!isDrawerOpen) {
    if (ramp?.version === "v2") {
      return (
        <Stack direction="column" alignItems="center" height={150}>
          <Stack
            direction="column"
            justifyContent="center"
            spacing={1}
            sx={{
              p: 1,
              maxHeight: "100%",
            }}
          >
            <MicroMetric
              icon={<ConnectingAirportsIcon />}
              value={`${formatNumber(nFullyOnRamp, 0)} / ${formatNumber(
                ramp.stack?.tenants?.length ?? 0,
                0
              )}`}
              tooltip={TOOLTIP_MESSAGES.aircraftOnRamp}
            />
            <MicroMetric
              icon={<PictureInPictureIcon />}
              value={`${utilizationFormatted} / ${totalPossibleUtilizationFormatted}`}
              tooltip={TOOLTIP_MESSAGES.utilization}
            />
            <MicroMetric
              icon={<SquareFootIcon />}
              value={parkableAreaDisplay}
              tooltip="Total square footage of parkable space on the ramp"
            />
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack direction="column" alignItems="center">
        <Stack
          direction="column"
          justifyContent="center"
          spacing={1}
          sx={{
            p: 1,
            maxHeight: "100%",
          }}
        >
          <MicroMetric
            icon={<ConnectingAirportsIcon />}
            value={`${formatNumber(ramp.stack?.tenants?.length ?? 0, 0)}`}
            tooltip={TOOLTIP_MESSAGES.parkableArea}
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 1,
        maxHeight: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography textAlign="center">Aircraft</Typography>
            <Tooltip title={TOOLTIP_MESSAGES.aircraftOnRamp}>
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
          <Typography variant="caption" textAlign="center" color="info.dark">
            {`${formatNumber(nFullyOnRamp, 0)} / ${formatNumber(
              ramp.stack?.tenants?.length ?? 0,
              0
            )}`}
          </Typography>
        </Stack>
        {ramp?.version === "v2" ? (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography textAlign="center">Utilization</Typography>
                <Tooltip title={TOOLTIP_MESSAGES.utilization}>
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {utilizationFormatted} / {totalPossibleUtilizationFormatted}
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Divider orientation="vertical" flexItem />
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography textAlign="center">Gross Dimensions</Typography>
                <Tooltip title={TOOLTIP_MESSAGES.grossDimensions}>
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {dimensions}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      {ramp?.version === "v2" && (
        <>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography textAlign="center">Parkable Area</Typography>
                <Tooltip title={TOOLTIP_MESSAGES.parkableArea}>
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {parkableAreaDisplay}
              </Typography>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography textAlign="center">Gross Dimensions</Typography>
                <Tooltip title={TOOLTIP_MESSAGES.grossDimensions}>
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {dimensions}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
