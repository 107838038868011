import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { round } from "lodash";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useApi } from "../../../containers/ApiContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useRampsState } from "../../../containers/RampsStateContainer";
import { formatNumber } from "../../../utils";
import { feetToMeters } from "../../../utils/math";
import { ConfirmationDialog } from "../../../widgets/ConfirmationDialog";
import { RampEditor } from "./RampEditor";

export const RampsSettingsPresenter: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeFBO, setFBO, fbos } = useActiveFBOs();
  const { airplxIdentity } = useIdentity();
  const { postgrest } = useApi();
  const { ramps, setRamps } = useRampsState();

  // check if it is the ramp edit page
  if (location.pathname.includes("/settings/ramp/")) {
    const regex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const match = location.pathname.match(regex);

    return (
      <RampEditor ramp_id={match[0]} onClose={() => navigate("/settings")} />
    );
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: false,
    },
    {
      field: "depth",
      headerName: "Gross Dimensions",
      width: 250,
      editable: false,
      valueFormatter: (value: any, row) =>
        value
          ? activeFBO?.settings?.useMetricSystem
            ? `${formatNumber(feetToMeters(row.width), 2)}w x ${formatNumber(
                feetToMeters(row.depth),
                2
              )}d`
            : `${round(row.width, 0)}w x ${round(row.depth, 0)}d`
          : "---",
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 400,
      renderCell: (params) => {
        const [confirmDelete, setConfirmDelete] = React.useState<boolean>(
          false
        );
        const [confirmCopy, setConfirmCopy] = React.useState<boolean>(false);
        const [fboIdToCopy, setFboIdToCopy] = React.useState<string | null>();
        React.useEffect(() => {
          if (confirmCopy) {
            setFboIdToCopy(null);
          }
        }, [confirmCopy]);
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {(airplxIdentity?.isadmin ||
              activeFBO.subscription === "autostacking_with_edit") && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    navigate(`/settings/ramp/${params.row.id}/edit`);
                    window.location.reload();
                  }}
                >
                  Modify Ramp
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete Ramp
                </Button>
              </>
            )}
            {airplxIdentity?.isadmin && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="inherit"
                  onClick={() => setConfirmCopy(true)}
                >
                  Copy Ramp
                </Button>
                <Dialog
                  open={confirmCopy}
                  onClose={() => setConfirmCopy(false)}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>Copy Ramp</DialogTitle>
                  <DialogContent>
                    <br />
                    <TextField
                      label="Copy to FBO"
                      variant="outlined"
                      select
                      fullWidth
                      onChange={(e) => setFboIdToCopy(e.target.value)}
                      value={fboIdToCopy}
                    >
                      {fbos
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((fbo) => (
                          <MenuItem key={fbo.id} value={fbo.id}>
                            {fbo.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmCopy(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      disabled={!fboIdToCopy}
                      onClick={async () => {
                        setConfirmCopy(false);
                        if (!fboIdToCopy) {
                          return;
                        }
                        await postgrest.rpc("copy_ramp", {
                          ramp_id: params.row.id,
                          destination_fbo_id: fboIdToCopy,
                        });
                        navigate("/settings?tab=Ramps");
                        window.location.reload();
                      }}
                    >
                      Copy Ramp
                    </Button>
                  </DialogActions>
                </Dialog>
                <IconButton
                  onClick={() => {
                    const index = ramps.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === 0) {
                      return;
                    }
                    // move index up one position in hangars array
                    const newRamps = [
                      ...ramps.slice(0, index - 1),
                      ramps[index],
                      ramps[index - 1],
                      ...ramps.slice(index + 1),
                    ];
                    const order = newRamps.map((h) => h.id);
                    setRamps(newRamps);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        rampDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const index = ramps.findIndex(
                      (h) => h.id === params.row.id
                    );
                    if (index === ramps.length - 1) {
                      return;
                    }
                    // move index back one position in hangars array
                    const newRamps = [
                      ...ramps.slice(0, index),
                      ramps[index + 1],
                      ramps[index],
                      ...ramps.slice(index + 2),
                    ];
                    const order = newRamps.map((h) => h.id);
                    setRamps(newRamps);
                    setFBO({
                      ...activeFBO,
                      settings: {
                        ...activeFBO.settings,
                        rampDisplayOrder: order,
                      },
                    });
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </>
            )}

            {confirmDelete && (
              <ConfirmationDialog
                title={`Are you sure you want to delete ${params.row.name}?`}
                text="This ramp and all of its tenant info will be deleted. Any base tenants for this ramp will become unassigned."
                typeToConfirm
                okText="Delete Ramp"
                onCancel={() => setConfirmDelete(false)}
                onOk={async () => {
                  // delete the hangar!
                  await postgrest.rpc("delete_ramp", {
                    ramp_id: params.row.id,
                  });
                  setRamps(ramps.filter((r) => r.id !== params.row.id));
                }}
                onClose={() => setConfirmDelete(false)}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <Stack direction="column" sx={{ height: "100%" }}>
      <div style={{ height: "calc(100% - 150px)", width: "100%" }}>
        <DataGrid
          disableRowSelectionOnClick
          rows={ramps}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 25 }}
        />
      </div>
    </Stack>
  );
};
