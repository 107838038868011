import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { featureCollection, polygon, union } from "@turf/turf";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { GarageDoor, Hangar } from "../../../types";
import { formatUnit } from "../../../utils";
import { MetricAwareTextField } from "../../../widgets/MetricAwareTextField/MetricAwareTextField";
import { calculateGeometryArea } from "../../Ramp/RampInfoCard";

type Props = {
  localHangar: Hangar;
  setLocalHangar: (hangar: Hangar) => void;
  setIGarageDoor: (idx: number, garageDoor: GarageDoor) => void;
  hideGarageDoors?: boolean;
};

export const HangarDimensionsForm: React.FC<Props> = ({
  localHangar,
  setLocalHangar,
  hideGarageDoors = false,
  setIGarageDoor,
}) => {
  const { activeFBO } = useActiveFBOs();
  const unit = activeFBO?.settings?.useMetricSystem ? "m" : "ft";
  const step = activeFBO?.settings?.useMetricSystem ? 0.1 : 0.5;

  const usableSquareFootage = React.useMemo(() => {
    const grossSquareFootage = localHangar?.width * localHangar?.depth;

    if (!activeFBO?.settings?.ignoreDeadSpaceForUtilization) {
      return grossSquareFootage;
    }

    try {
      const obstaclePolygons = localHangar.obstacles?.map((obstacle) => {
        const p = polygon([
          [
            [obstacle.x, obstacle.y],
            [obstacle.x, obstacle.y + obstacle.depth],
            [obstacle.x + obstacle.width, obstacle.y + obstacle.depth],
            [obstacle.x + obstacle.width, obstacle.y],
            [obstacle.x, obstacle.y],
          ],
        ]);
        return p;
      });
      const spacingPolygons = [
        polygon([
          [
            [0, 0],
            [0, localHangar.depth],
            [localHangar.wall_spacing_left, localHangar.depth],
            [localHangar.wall_spacing_left, 0],
            [0, 0],
          ],
        ]),
        polygon([
          [
            [localHangar.width || 0, 0],
            [localHangar.width || 0, localHangar.depth || 0],
            [
              localHangar.width || 0 - localHangar.wall_spacing_right || 0,
              localHangar.depth || 0,
            ],
            [localHangar.width || 0 - localHangar.wall_spacing_right || 0, 0],
            [localHangar.width || 0, 0],
          ],
        ]),
        polygon([
          [
            [0, localHangar.depth || 0],
            [localHangar.width || 0, localHangar.depth || 0],
            [
              localHangar.width || 0,
              localHangar.depth || 0 - localHangar.wall_spacing_back || 0,
            ],
            [0, localHangar.depth || 0 - localHangar.wall_spacing_back || 0],
            [0, localHangar.depth || 0],
          ],
        ]),
      ].filter((x) => false);

      const unionPolygon = union(
        featureCollection([...obstaclePolygons, ...spacingPolygons])
      );

      if (!unionPolygon) {
        console.info("No union polygon");
        return grossSquareFootage;
      }
      const obstacleSquareFootage = calculateGeometryArea(
        unionPolygon.geometry
      );
      return grossSquareFootage - obstacleSquareFootage;
    } catch (e) {
      console.error(e);
      // if all else fails just return the gross square footage
      return grossSquareFootage;
    }
  }, [localHangar]);

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <MetricAwareTextField
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          disabled={activeFBO.subscription === "standard"}
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 50, max: 600 } }}
          size="small"
          label={`Width (${unit})`}
          onChange={(value) =>
            setLocalHangar({
              ...localHangar,
              width: Math.min(600, value),
            })
          }
          value={localHangar?.width}
        />
        <MetricAwareTextField
          unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
          disabled={activeFBO.subscription === "standard"}
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 50, max: 600 } }}
          size="small"
          label={`Depth (${unit})`}
          onChange={(value) =>
            setLocalHangar({
              ...localHangar,
              depth: Math.min(600, value),
            })
          }
          value={localHangar?.depth}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-around">
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography>Gross Square Footage</Typography>
          <Typography>
            {formatUnit(
              localHangar?.width * localHangar?.depth,
              true,
              activeFBO?.settings?.useMetricSystem
            )}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography>Usable Square Footage</Typography>
          <Typography>
            {formatUnit(
              usableSquareFootage,
              true,
              activeFBO?.settings?.useMetricSystem
            )}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Door Pockets</Typography>
        <Stack direction="row" spacing={2}>
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={activeFBO.subscription === "standard"}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 200 } }}
            size="small"
            label="Left Door Length (ft)"
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                left_door: value,
              })
            }
            value={localHangar?.left_door}
          />
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={activeFBO.subscription === "standard"}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 200 } }}
            size="small"
            label="Right Door Length (ft)"
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                right_door: value,
              })
            }
            value={localHangar?.right_door}
          />
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Door Dimensions</Typography>
        <Stack direction="row" spacing={2}>
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={activeFBO.subscription === "standard"}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 1, max: 200 } }}
            size="small"
            label="Height (ft)"
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                height: value,
              })
            }
            value={localHangar?.height}
          />
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            disabled={true}
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 1, max: 200 } }}
            size="small"
            label="Width (ft)"
            value={
              localHangar?.width -
              (localHangar?.left_door + localHangar?.right_door)
            }
            onChange={() => {}}
          />
        </Stack>
      </Stack>
      {hideGarageDoors ? (
        <div />
      ) : (
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Garage Doors</Typography>
            <Button
              disabled={activeFBO.subscription === "standard"}
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                setLocalHangar({
                  ...localHangar,
                  garageDoors: localHangar.garageDoors.concat([
                    {
                      id: uuidv4(),
                      fbo_id: activeFBO.id,
                      hangar_id: localHangar.id,
                      wall: "left",
                      x: 0,
                      y: 0,
                      width: 10,
                    },
                  ]),
                });
              }}
            >
              Add
            </Button>
          </Stack>
          {localHangar?.garageDoors?.map((garageDoor, idx) => (
            <Stack key={`garage-door-${idx}`} direction="row" spacing={2}>
              <TextField
                disabled={activeFBO.subscription === "standard"}
                select
                size="small"
                label="Wall"
                fullWidth
                onChange={(evt) => {
                  const wall = evt.target.value;
                  const x =
                    wall === "back"
                      ? garageDoor.x
                      : wall === "right"
                      ? localHangar?.width
                      : 0;
                  const y = wall !== "back" ? garageDoor.y : localHangar?.depth;
                  setIGarageDoor(idx, {
                    ...garageDoor,
                    wall,
                    x,
                    y,
                  });
                }}
                value={garageDoor.wall}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="back">Back</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </TextField>

              <MetricAwareTextField
                unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
                disabled={activeFBO.subscription === "standard"}
                type="number"
                fullWidth
                inputProps={{
                  min: 0,
                  max:
                    garageDoor.wall === "back"
                      ? localHangar.width
                      : localHangar.depth,
                  step,
                }}
                size="small"
                onChange={(width) => {
                  setIGarageDoor(idx, {
                    ...garageDoor,
                    width,
                  });
                }}
                value={garageDoor.width}
                label="Width"
              />
              <IconButton
                disabled={activeFBO.subscription === "standard"}
                color="error"
                onClick={() =>
                  setLocalHangar({
                    ...localHangar,
                    garageDoors: localHangar.garageDoors.filter(
                      (x, i) => i !== idx
                    ),
                  })
                }
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack direction="column" spacing={1}>
        <Typography variant="body2">Wall Spacing</Typography>
        <Stack direction="row" spacing={2}>
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 15, step }}
            size="small"
            label={`Left Wall (${unit})`}
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                wall_spacing_left: value,
              })
            }
            value={localHangar?.wall_spacing_left}
          />
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 15, step }}
            size="small"
            label={`Back Wall (${unit})`}
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                wall_spacing_back: value,
              })
            }
            value={localHangar?.wall_spacing_back}
          />
          <MetricAwareTextField
            unit={activeFBO?.settings?.useMetricSystem ? "meters" : "feet"}
            type="number"
            fullWidth
            inputProps={{ min: 0, max: 15, step }}
            size="small"
            label={`Right Wall (${unit})`}
            onChange={(value) =>
              setLocalHangar({
                ...localHangar,
                wall_spacing_right: value,
              })
            }
            value={localHangar?.wall_spacing_right}
          />
        </Stack>
      </Stack>
    </>
  );
};
