import * as React from "react";
import { useLocalStorage, usePrevious } from "react-use";
import { FBO } from "../types";
import { envName } from "../utils";
import mixpanel from "../utils/mixpanel";
import { useApi } from "./ApiContainer";
import { useIdentity } from "./IdentityContainer";
import { createContainer } from "./StateContainer";

type Props = {};
const useActiveFBOContainerState = (props: Props) => {
  const { postgrest } = useApi();
  const { airplxIdentity } = useIdentity();
  const [fbos, setFbos] = React.useState<FBO[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [activeFBOId, setActiveFBOId] = useLocalStorage<string>(
    `airplx/${envName()}/activeFBOId`,
    ""
  );
  const previousFbos = usePrevious(fbos);

  React.useEffect(() => {
    (async () => {
      if (!airplxIdentity.userid) {
        return;
      }
      const fbos = await postgrest
        .from("fbo")
        .select()
        .select("*,fbo_employees(_role)")
        .eq("fbo_employees.user_id", airplxIdentity.userid)
        .order("name", { ascending: true })
        .then((r) => {
          return (r.body || []).map(({ fbo_employees, ...row }) => ({
            ...row,
            user_role: fbo_employees?.[0]?._role ?? "operator",
          }));
        })
        .then((data) => data as FBO[]);
      setFbos(fbos);

      if (!activeFBOId && fbos.length) {
        setActiveFBOId(fbos[0].id);
      }
      setIsLoading(false);
    })();
  }, [airplxIdentity]);

  React.useEffect(() => {
    (async () => {
      if (!fbos || !previousFbos) {
        return;
      }

      for (const fbo of fbos) {
        const previousFbo = previousFbos.find((prev) => prev.id === fbo.id);
        if (
          previousFbo &&
          JSON.stringify(previousFbo) !== JSON.stringify(fbo)
        ) {
          const { id, user_role, ...otherFboData } = fbo;
          await postgrest
            .from("fbo")
            .update({
              ...otherFboData,
            })
            .eq("id", fbo.id);
        }
      }
    })();
  }, [fbos]);

  const activeFBO = fbos?.find((fbo) =>
    Boolean(activeFBOId) ? fbo.id === activeFBOId : true
  );

  const setFBO = React.useCallback(
    (fbo: FBO) => {
      setFbos(fbos.map((f) => (f.id === fbo.id ? fbo : f)));
    },
    [fbos]
  );

  React.useEffect(() => {
    if (activeFBO) {
      mixpanel.register({
        fbo: activeFBO.name,
      });
    }
  }, [activeFBO]);

  return {
    ready: !isLoading,
    activeFBO,
    setActiveFBOId,
    fbos: fbos ?? [],
    setFbos,
    setFBO,
  };
};
const ActiveFBOContainerState = createContainer(useActiveFBOContainerState);

export const ActiveFBOContainer: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const nested = ActiveFBOContainerState.useContainer();
  return (
    <ActiveFBOContainerState.Provider initialState={{ ...nested, ...props }}>
      {children}
    </ActiveFBOContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useActiveFBOs = ActiveFBOContainerState.useContainer;
