import LinkIcon from "@mui/icons-material/Link";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import * as jwt from "jsonwebtoken";
import * as React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-use";
import logo from "../../../airplx-logo-transparent-square-no-text.png";
import { Identity, useIdentity } from "../../containers/IdentityContainer";

export const MagicLinkLoginButton: React.FC<{}> = () => {
  return (
    <Tooltip
      title={`Magic Link Login allows user to securely login without a password. A link will be sent to the email address associated with your account.`}
    >
      <Button
        component={Link}
        to="/login/link"
        variant="outlined"
        fullWidth
        startIcon={<LinkIcon />}
        sx={{
          borderRadius: 1,
          borderColor: "lightgrey",
          "&:hover": {
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        Login with Magic Link
      </Button>
    </Tooltip>
  );
};

export const Login: React.FC<{
  magicLink?: boolean;
}> = ({ magicLink = false }) => {
  const { isLoggedIn, loginWithMicrosoft, loginWithGoogle } = useIdentity();
  const location = useLocation();

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          sx={{
            width: 368,
            margin: "auto",
            border: "2px solid #E2E2E2",
            p: 3,
            mt: 20,
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography align="center" variant="h4">
              Welcome Back
            </Typography>
            <Typography align="center" variant="subtitle1">
              Select your authentication provider.
            </Typography>
          </Stack>
          <br />
          <br />
          <Stack direction={"column"} justifyContent="space-around" spacing={2}>
            <Button
              variant="outlined"
              sx={{ width: "365px", borderRadius: 1, borderColor: "lightgrey" }}
              onClick={async () => {
                await loginWithMicrosoft();
                window.location.href = location.state?.usr?.from || "/#/";
                window.location.reload();
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <img
                  src="https://img.icons8.com/color/48/000000/microsoft.png"
                  style={{ width: 25 }}
                />
                <Typography variant="body2" sx={{ color: "rgb(60, 64, 67)" }}>
                  Login with Microsoft
                </Typography>
                <p></p>
              </Stack>
            </Button>
            <GoogleLogin
              width="365px"
              onSuccess={async (credentialResponse) => {
                const token = credentialResponse.credential;
                const identity = jwt.decode(token);
                await loginWithGoogle(token, identity as Identity);
                window.location.href = location.state?.usr?.from || "/#/";
                window.location.reload();
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            {magicLink && <MagicLinkLoginButton />}
          </Stack>
        </Box>
      </Stack>
      <img
        src={logo}
        style={{ position: "fixed", bottom: 15, right: 15, width: 50 }}
      />
    </>
  );
};
